.validation-wrap {
  
  .with-text {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 5px 10px;
    font-size: 0.75rem;
    background: $brand-secondary;
    color: #fff;
  }
  .with-text-left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 5px 10px;
    font-size: 0.75rem;
    background: $brand-secondary;
    color: #fff;
  }
}

.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: $brand-secondary;
}

.mat-form-field-invalid .mat-form-field-label {
  color: $brand-secondary;
}

.mat-form-field-invalid .mat-form-field-ripple {
  background-color: $brand-secondary;
}
