.v1 .ql-snow .ql-editor h1 {
  font-size: 14pt;
  font-family: 'CarlitoBold';
  line-height: 1.08;
  color: #fe0041;
  text-align: left;
}

.v1 .ql-snow .ql-editor h2 {
  font-family:'Carlito';
  font-size: 12pt;
  line-height: 1.2;
  text-align: left;
  color: #333333;
}

.v1 .ql-snow .ql-editor h3 {
  font-family: 'CarlitoBold';
  font-size: 16pt;
  font-weight: 700;
  line-height: 1.08;
  text-align: left;
  color: #fe0041;
}

.v1 .ql-container {
  font-family:'Carlito';
  font-size: 11pt;
  line-height: 1.2;
  text-align: left;
  color: #333333;
}

.v1 .ql-editor strong {
  font-family: 'CarlitoBold';
}

.v1 .ql-editor .ql-size-small {
  font-size: 9pt;
  line-height: 1.2;
}

.v1 .ql-editor .ql-size-large {
  font-size: 11pt;
}