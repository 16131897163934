.bl .ql-snow .ql-editor h1 {
  font-family: 'EB Garamond';
  font-size: 13pt;
  font-weight: 700;
  line-height: 1.08;
  color: #d3b478;
  text-align: left;
}
  
.bl .ql-snow .ql-editor h2 {
  font-family: 'Carlito';
  font-size: 11pt;
  font-weight: 300;
  line-height: 1.2;
  text-align: left;
  color: #5a6462;
}

.bl .ql-snow .ql-editor h3 {
  font-family: 'EB Garamont';
  font-size: 16pt;
  font-weight: 700;
  line-height: 1.08;
  text-align: left;
  color: #d3b478;
}

.bl .ql-container {
  font-family:'Carlito';
  font-size: 10pt;
  line-height: 1.3;
  text-align: left;
  color: #5a6462;
  font-weight: 300;
}

.bl .ql-editor strong {
  font-weight: 700;
}

.bl .ql-editor .ql-size-small {
  font-size: 9pt;
  line-height: 1.2;
}

.bl .ql-editor .ql-size-large {
  font-size: 11pt;
}