$brand-primary: #002c81;
$brand-primary-dark: #404042;
$brand-secondary: #fd3e00;
$brand-background: #e8e8e8;
$brand-warning: #ffda00;
$brand-success: #3bda6b;

$brand-text-dark: #404042;
$brand-text-bright: #ffffff;
$brand-text-grey: #ccc;
