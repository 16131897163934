.ueberschrift-bl {
  font-family: 'EB Garamond';
  font-size: 13pt;
  font-weight: 700;
  line-height: 1.08;
  color: #ae8135;
  text-align: left;
}

.botschaft-bl,
.botschaft-bl-bold,
.botschaft-bl-highlight {
  font-family: 'Carlito';
  font-size: 11pt;
  font-weight: 300;
  line-height: 1.2;
  text-align: left;
  color: #576167;
}

.botschaft-bl-bold {
  font-weight: 700;
}

.botschaft-bl-highlight {
  font-weight: 700;
  color: #ae8135;
}

.text-bl,
.text-bl-justify,
.text-bl-bold,
.text-bl-highlight,
.text-bl-big,
.text-bl-preis,
.text-bl-tiny {
  font-family:'Carlito';
  font-size: 10pt;
  line-height: 1.3;
  text-align: left;
  color: #576167;
  font-weight: 300;
}

.text-bl-justify {
  text-align: justify;
}

.text-bl-bold {
  font-weight: 700;
}

.text-bl-highlight {
  color: #ae8135;
}

.text-bl-big {
  font-size: 11pt;
}

.text-bl-preis {
  text-align: right;
}

.text-bl-tiny {
  font-size: 9pt;
  line-height: 1.2;
}

/* Support old offers */
.ueberschrift-bl-highlight {
  font-family: 'Carlito';
  font-size: 13pt;
  font-weight: 700;
  line-height: 1.08;
  color: #ae8135;
  text-align: left;
}

.text-bl-footer {
  font-size: 8pt;
  line-height: 2.0;
}

.caption-bl,
.caption-bl-highlight,
.caption-bl-bold {
  font-family:'Carlito';
  font-size: 9pt;
  line-height: 1.2;
  text-align: center;
  color: #ae8135;}

.caption-bl-highlight {
  color: #ae8135;
}

.caption-bl-bold {
  font-weight: 500;
}
