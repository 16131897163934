//Navigation

/*
The mene which is updating the Status (top right)
 */
.app-menu{
    .mat-mdc-button{
        margin-left: 7px;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-toggle-group{
        margin-left: 10px;
        height: 38px;
        align-items: center; 
    }
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-toggle-label-content {
        font-size: 15px;
        color: $brand-text-dark;
        text-transform: uppercase;
        text-decoration: none;
    }

    .status-link{
        position: relative;
        transition: all 0.1s;
        min-height: 35px;
        min-width: 35px;
        border: 1px solid rgba(0,0,0,0.15);
        margin-left: 10px;
        background: rgba(0,0,0,0);
        .icon{
            position: absolute;
            transition: opacity 0.1s;
            height: 80%;
            width: 80%;
            top: 10%;
            left: 12%;
            opacity: 0.5;
        }
        
        &:hover{
            background: rgba(0,0,0,0.05);
            .icon{
                opacity: 0.75;
            }
        }
        &.active{
            border: 2px solid rgba(0,0,0,0.15);
            background: rgba(0,0,0,0.05);
        }
    }
}

.offer--sidebar {

    mat-expansion-panel-header{
        padding: 0 15px;
    }

    .offer--menu{

        .offer--menu-block{
            .offer--menu-block-title{
                li{
                    padding: 5px 15px;
                    opacity: 0.5;
                    font-size: 12px;
                    background: rgba(0,0,0,0.1);

                }
            }
            .offer--menu-block-list{
                li{
                    padding: 5px;
                    position: relative;
                    .icon{
                        transition: opacity 0.1s;
                        position: absolute;
                        height: 22px;
                        right: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                        opacity: 0;
                        pointer-events: none;
                    }
                }
            }

        }

    }
}

.block-invalid {
  border-color: red !important;
}
.block-invalid::before {
  font-family: FontAwesome;
  content: "\f071";
  position: absolute;
  margin-left: -2.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.input-invalid {
  color: red !important;
}
.input-invalid::before {
  font-family: FontAwesome;
  content: "\f071";
}

//.firstpage {
//    margin-top: 64px;
//
//    .offer--container .offer--wrap .offer--content {
//        top: calc(100 / 30 * 10.5vw);
//
//        @media screen and (min-width: 1130px){
//            top: 10.5cm;
//        }
//    }
//}

.offer--footer {
    width: 100%;
    margin-top: 15px;
    .page--info {
        float: right;
        color: $brand-text-grey;
    }
}


//dnd-2
.dnd-drag-start {
    opacity:0.5;
    border: 2px dashed rgba(0,0,0,0.1);
}
.dropzone {
  text-align: center;
  margin-top: 10px;
  border-color: grey;
  background: repeating-linear-gradient(
                  45deg,
                  rgba(0, 0, 0, 0.1),
                  rgba(0, 0, 0, 0.1) 10px,
                  #ffffff 10px,
                  #ffffff 20px
  );

  &.dnd-drag-enter {
    background: repeating-linear-gradient(
                    45deg,
                    rgba(0, 0, 0, 0.3),
                    rgba(0, 0, 0, 0.3) 10px,
                    #ffffff 10px,
                    #ffffff 20px
    );
  }

  &.dnd-drag-over {
  }

}

.dnd-sortable-drag {
    -moz-transform:scale(0.9);
    -webkit-transform:scale(0.9);
    transform:scale(0.9);
    opacity:0.7;
    border: 2px dashed rgba(0,0,0,0.1);
}

.mat-row:hover {
  background-color: #F3FBFF;
}

@media screen and (min-width: 1200px) {
    .backdrop-offer{
        background: rgba($brand-text-dark, .5);
    }
    .panel-offer{
        margin-left: 320px;
        width: 860px;
        display: flex;
        position: absolute;
        z-index: 1000;
        overflow: auto;
        pointer-events: auto;
        .offer--option-preview{
            font-size: 14px;
            font-weight: normal;
            .option {
                border: 2px dashed rgba(0, 0, 0, 0.1);
                margin-top: 4px;
                position: relative;
                transition: opacity 0.25s ease-in-out;
                font-size: 14px;
                padding-left: 15px;
                padding-right: 15px;
                padding-top: 10px;
                padding-bottom: 10px;
                box-shadow: 0px 5px 50px rgba(0,0,0,0.10);
                span{
                    font-weight: bold;
                    color: $brand-secondary;
                }
            }
        }
        .offer--option-edit{
            margin-top: 24px;
        }
    }
}


app-custom-dynamic {
    line-height: 1.25;
    #insert {
        padding-left: 87px;
        padding-right: 87px;
    }
    ul {
        list-style-type: disc;
        list-style-position: inside;

        li {
            list-style: inherit;
        }
    }
}
