.standard-form .ql-snow .ql-editor h1 {
  font-family:'Roboto';
  font-size: 13pt;
  font-weight: 700;
  line-height: 1.08;
  /* color: #002c81; */
  text-align: left;
}

.standard-form .ql-snow .ql-editor h2 {
  font-family:'Roboto';
  font-size: 11pt;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  /* color: #333333; */
}

.standard-form .ql-snow .ql-editor h3 {
  /* font-family: 'Roboto';
  font-size: 16pt;
  font-weight: 700;
  line-height: 1.08;
  text-align: left; */
  /* color: #002c81; */
}

.standard-form .ql-container {
  font-family:'Roboto';
  font-size: 10pt;
  line-height: 1.2;
  text-align: left;
  /* color: #333333; */
  font-weight: 400;
}

.standard-form .ql-editor strong {
  font-weight: 700;
}

.standard-form .ql-editor .ql-size-small {
  font-size: 8pt;
  line-height: 1.2;
}

.standard-form .ql-editor .ql-size-large {
  font-size: 11pt;
}