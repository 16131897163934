.login{
  min-height: 100vh;
  min-width:  100vw;
  padding: 0;
  margin: 0;
  background-image: url('/assets/images/af_background_content1.jpg');
  background-size: cover;
  background-position: center;
  &.safemode {
    background-image: url('/assets/images/af_background_maintenence3.jpg')!important;
    //background-size: 35%;
    //background-repeat: no-repeat;
  }

  .mat-card{
    padding: 35px;
    box-shadow: 0px 5px 25px rgba(0,0,0,0.75)!important;
  }

  .mat-card-title {
    text-transform: uppercase;
    font-weight: 600;
    transform: scaleY(0.9);
  }

  .login-card {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 90%;
    max-width: 400px;
    margin-top: -155px;
    top: 50%;
  }

  .btn{
    width: 100%;
  }

  .txt-link{
    color: rgba($brand-text-dark, .5);
    float: right;
    padding: 15px 10px 0;
  }
}
