//---------------------------------------------------------------------------------------------------------------------
@use '@angular/material' as mat;
// ANGULAR - MATERIAL STYLES
//---------------------------------------------------------------------------------------------------------------------
//@import "@angular/material/_theming";
@import '../../node_modules/@angular/material/_theming.scss';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
/*TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
@include mat.all-legacy-component-typographies();
@include mat.all-component-typographies();
/*TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.legacy-core();
@include mat.core();

@tailwind base;
@tailwind components;
@tailwind utilities;

//FONTS
@import 'fonts';

//COLORS
@import 'variables';

//THEME
@import 'theme/angebotsfarbrik';


// QUILLJSS
 @import 'quill/quill.core.css';
 @import 'quill/quill.bubble.css';
 @import 'quill/quill.snow.css';
 @import 'quill/quill.standard.css';
 @import 'quill/quill.var1.css';
 @import 'quill/quill.var2.css';
 @import 'quill/quill.bl.css';
 @import 'quill/quill.dcs.css';
 @import 'quill/quill.form.css';


 @import '../../node_modules/codemirror/lib/codemirror';
 @import '../../node_modules/codemirror/theme/material';

//---------------------------------------------------------------------------------------------------------------------
// APP STYLES - OVERRIDES
//---------------------------------------------------------------------------------------------------------------------

// FONTS
//@import url(https://fonts.googleapis.com/css?family=Roboto:400,700);

// DEPENDENCIES

// CORE VARIABLES AND MIXINS
@import "bootstrap-grid";

// LAYOUT SETTINGS
@import 'scaffolding/overwrites';
@import 'partials/header';
@import 'partials/footer';
@import 'partials/offer';
@import 'partials/status';
@import 'partials/validation';
@import 'partials/systemalerts';
@import 'partials/footervariants';

// Individuel layout for blocks
@import 'blocks/bl.css';
@import 'blocks/standard.css';
@import 'blocks/variante_1.css';
@import 'blocks/variante_2.css';
@import 'blocks/dcs.css';
@import 'blocks/form.css';

// MODULES

// SECTIONS

// VIEWS
@import 'all';
@import 'views/login';



