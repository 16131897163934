.ueberschrift-v2 {
  font-size: 14pt;
  font-family: 'CarlitoBold';
  line-height: 1.08;
  color: #ff3e00;
  text-align: left;
}

.botschaft-v2,
.botschaft-v2-bold,
.botschaft-v2-highlight {
  font-family:'Carlito';
  font-size: 12pt;
  line-height: 1.2;
  text-align: left;
  color: #333333;
}

.botschaft-v2-bold {
  font-family: 'CarlitoBold';
}

.botschaft-v2-highlight {
  font-family: 'CarlitoBold';
  color: #ff3e00;
}

.text-v2,
.text-v2-justify,
.text-v2-bold,
.text-v2-highlight,
.text-v2-big,
.text-v2-preis,
.text-v2-tiny {
  font-family:'Carlito';
  font-size: 11pt;
  line-height: 1.2;
  text-align: left;
  color: #333333;
}

.text-v2-justify {
  text-align: justify;
}

.text-v2-bold {
  font-family: 'CarlitoBold';
}

.text-v2-highlight {
  color: #ff3e00;
}

.text-v2-big {
  font-size: 11pt;
}

.text-v2-preis {
  text-align: right;
}

.text-v2-tiny {
  font-size: 9pt;
  line-height: 1.2;
}

.caption-v2,
.caption-v2-highlight,
.caption-v2-bold {
  font-family:'Carlito';
  font-size: 9pt;
  line-height: 1.2;
  text-align: center;
  color: #000000;}

.caption-v2-highlight {
  color: #ff3e00;
}

.caption-v2-bold {
  font-weight: 500;
}
