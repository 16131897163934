@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;
@include mat.core();

$mat-angebotsfabrik-primary: (
        50:  lighten($brand-primary, 90),
        100: lighten($brand-primary, 80),
        200: lighten($brand-primary, 70),
        300: lighten($brand-primary, 60),
        400: lighten($brand-primary, 50),
        500: lighten($brand-primary, 40),
        600: lighten($brand-primary, 30),
        700: lighten($brand-primary, 20),
        800: lighten($brand-primary, 10),
        900: lighten($brand-primary, 0),
        A100: darken($brand-primary, 5),
        A200: darken($brand-primary, 10),
        A400: darken($brand-primary, 20),
        A700: darken($brand-primary, 30),
        contrast: (
                50: rgba($brand-text-bright, .9),
                100: rgba($brand-text-bright, .9),
                200: rgba($brand-text-bright, .9),
                300: rgba($brand-text-bright, .9),
                400: rgba($brand-text-bright, .9),
                500: rgba($brand-text-bright, .9),
                600: rgba($brand-text-bright, .9),
                700: rgba($brand-text-bright, .9),
                800: rgba($brand-text-bright, .9),
                900: rgba($brand-text-bright, 1),
                A100: rgba($brand-text-bright, .9),
                A200: rgba($brand-text-bright, .9),
                A400: rgba($brand-text-bright, .9),
                A700: rgba($brand-text-bright, .9),
        )
);

$mat-angebotsfabrik-secondary: (
        50:  lighten($brand-secondary, 90),
        100: lighten($brand-secondary, 80),
        200: lighten($brand-secondary, 70),
        300: lighten($brand-secondary, 60),
        400: lighten($brand-secondary, 50),
        500: lighten($brand-secondary, 40),
        600: lighten($brand-secondary, 30),
        700: lighten($brand-secondary, 20),
        800: lighten($brand-secondary, 10),
        900: lighten($brand-secondary, 0),
        A100: darken($brand-secondary, 5),
        A200: darken($brand-secondary, 10),
        A400: darken($brand-secondary, 20),
        A700: darken($brand-secondary, 30),
        contrast: (
                50: rgba($brand-text-dark, .9),
                100: rgba($brand-text-dark, .9),
                200: rgba($brand-text-dark, .9),
                300: rgba($brand-text-dark, .9),
                400: rgba($brand-text-dark, .9),
                500: rgba($brand-text-dark, .9),
                600: rgba($brand-text-dark, .9),
                700: rgba($brand-text-dark, .9),
                800: rgba($brand-text-dark, .9),
                900: rgba($brand-text-dark, 1),
                A100: rgba($brand-text-dark, .9),
                A200: rgba($brand-text-dark, .9),
                A400: rgba($brand-text-dark, .9),
                A700: rgba($brand-text-dark, .9),
        )
);

$angebotsfabrik-app-primary: mat.define-palette($mat-angebotsfabrik-primary, 900);
$angebotsfabrik-app-accent:  mat.define-palette($mat-angebotsfabrik-secondary, 900);


// The warn palette is optional (defaults to red).
$angebotsfabrik-app-warn:    mat.define-palette($mat-angebotsfabrik-secondary, 900);


// Create the theme object (a Sass map containing all of the palettes).
// $angebotsfabrik-app-theme: mat.define-light-theme($angebotsfabrik-app-primary, $angebotsfabrik-app-accent, $angebotsfabrik-app-warn);

$angebotsfabrik-app-theme: mat.define-light-theme((
 color: (
   primary: $angebotsfabrik-app-primary,
   accent: $angebotsfabrik-app-accent,
   warn: $angebotsfabrik-app-warn,
 ),
//  typography: mat.define-typography-config(),
//  density: 0,
));

@include mat.core-theme($angebotsfabrik-app-theme);
@include mat.all-component-themes($angebotsfabrik-app-theme);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
@include mat.all-legacy-component-themes($angebotsfabrik-app-theme);
@include mat.all-component-themes($angebotsfabrik-app-theme);
@include mtx.all-component-themes($angebotsfabrik-app-theme);