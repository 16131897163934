/* Footer mit 3 Zeilen in 3 unterschiedlichen Farben*/
.footer-1,
.footer-1-bold,
.footer-1-highlight-1,
.footer-1-highlight-2,
.footer-1-highlight-3 {
  font-family:'Carlito';
  font-size: 8pt;
  line-height: 1.6;
  color: #333333;
}

.footer-1-bold {
  font-family: 'CarlitoBold';
}

.footer-1-highlight-1 {
  color: #002c81;
  font-family: 'CarlitoBold';
}

.footer-1-highlight-2 {
  color: #fe0041;
  font-family: 'CarlitoBold';
}

.footer-1-highlight-3 {
  color: #ff3e00;
  font-family: 'CarlitoBold';
}

.footer-1-logo {
  max-height: 90px;
  height: auto;
  width: auto;
}

/* Footer mit 4 Zeilen in 3 unterschiedlichen Farben*/
.footer-2,
.footer-2-bold,
.footer-2-highlight-1,
.footer-2-highlight-2,
.footer-2-highlight-3 {
  font-family:'Carlito';
  font-size: 8pt;
  line-height: 1.4;
  color: #333333;
}

.footer-2-bold {
  font-family: 'CarlitoBold';
}

.footer-2-highlight-1 {
  color: #002c81;
  font-family: 'CarlitoBold';
}

.footer-2-highlight-2 {
  color: #fe0041;
  font-family: 'CarlitoBold';
}

.footer-2-highlight-3 {
  color: #ff3e00;
  font-family: 'CarlitoBold';
}

.footer-2-logo {
  max-height: 100px;
  height: auto;
  width: auto;
}
