header{
    //margin-bottom: 50px;
    //margin: 0 -15px;
    //padding: 0 15px;

    nav{
        right: 15px;
        position: absolute;
    }

    .app-menu-position-right{
        right: 15px;
        position: absolute;
    }

    .app-menu-position-left{
        left: 320px;
        position: absolute;
    }
    
    .app-menu {
        ul {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }

        li{ 
            a {
                font-size: 15px;
                color: $brand-text-dark;
                text-transform: uppercase;
                text-decoration: none;
            }
        }

    }
    // .hotline{
    //     padding-left: 15px;
    //     padding-top: 25px;
    //     color: $brand-secondary;
    // }
    .logo{
        padding-left: 25px;
        min-width: 150px;
    }
}

header.main--header {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;

    z-index: 9999;
}
