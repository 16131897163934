.standard .ql-snow .ql-editor h1 {
  font-family:'CarlitoBold';
  font-size: 14pt;
  font-weight: 700;
  line-height: 1.08;
  color: #002c81;
  text-align: left;
}

.standard .ql-snow .ql-editor h2 {
  font-family:'Carlito';
  font-size: 12pt;
  font-weight: 300;
  line-height: 1.2;
  text-align: left;
  color: #333333;
}

.standard .ql-snow .ql-editor h3 {
  font-family: 'CarlitoBold';
  font-size: 16pt;
  font-weight: 700;
  line-height: 1.08;
  text-align: left;
  color: #002c81;
}

.standard .ql-container {
  font-family:'Carlito';
  font-size: 11pt;
  line-height: 1.2;
  text-align: left;
  color: #333333;
}

.standard .ql-editor strong {
  font-family: 'CarlitoBold';
}

.standard .ql-editor .ql-size-small {
  font-size: 9pt;
  line-height: 1.2;
}

.standard .ql-editor .ql-size-large {
  font-size: 11pt;
}