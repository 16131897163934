.ueberschrift-v1 {
  font-size: 14pt;
  font-family: 'CarlitoBold';
  line-height: 1.08;
  color: #fe0041;
  text-align: left;
}

.botschaft-v1,
.botschaft-v1-bold,
.botschaft-v1-highlight {
  font-family:'Carlito';
  font-size: 12pt;
  line-height: 1.2;
  text-align: left;
  color: #333333;
}

.botschaft-v1-bold {
  font-family: 'CarlitoBold';
}

.botschaft-v1-highlight {
  font-weight: 700;
  color: #fe0041;
}

.text-v1,
.text-v1-justify,
.text-v1-bold,
.text-v1-highlight,
.text-v1-big,
.text-v1-preis,
.text-v1-tiny {
  font-family:'Carlito';
  font-size: 11pt;
  line-height: 1.2;
  text-align: left;
  color: #333333;
}

.text-v1-justify {
  text-align: justify;
}

.text-v1-bold {
  font-family: 'CarlitoBold';
}

.text-v1-highlight {
  color: #fe0041;
}

.text-v1-big {
  font-size: 11pt;
}

.text-v1-preis {
  text-align: right;
}

.text-v1-tiny {
  font-size: 9pt;
  line-height: 1.2;
}

.caption-v1,
.caption-v1-highlight,
.caption-v1-bold {
  font-family:'Carlito';
  font-size: 9pt;
  line-height: 1.2;
  text-align: center;
  color: #000000;}

.caption-v1-highlight {
  color: #fe0041;
}

.caption-v1-bold {
  font-weight: 500;
}