/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/roboto/roboto-v30-latin-regular.eot');
    /* IE9 Compat Modes */
    src:
        local(''),
        url('../assets/fonts/roboto/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../assets/fonts/roboto/roboto-v30-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../assets/fonts/roboto/roboto-v30-latin-regular.woff') format('woff'),
        /* Modern Browsers */ url('../assets/fonts/roboto/roboto-v30-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../assets/fonts/roboto/roboto-v30-latin-regular.svg#Roboto') format('svg');
    /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/roboto/roboto-v30-latin-700.eot');
    /* IE9 Compat Modes */
    src:
        local(''),
        url('../assets/fonts/roboto/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../assets/fonts/roboto/roboto-v30-latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../assets/fonts/roboto/roboto-v30-latin-700.woff') format('woff'),
        /* Modern Browsers */ url('../assets/fonts/roboto/roboto-v30-latin-700.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../assets/fonts/roboto/roboto-v30-latin-700.svg#Roboto') format('svg');
    /* Legacy iOS */
}

/* ubuntu-regular - latin */
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/ubuntu/ubuntu-v20-latin-regular.eot');
    /* IE9 Compat Modes */
    src:
        local(''),
        url('../assets/fonts/ubuntu/ubuntu-v20-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../assets/fonts/ubuntu/ubuntu-v20-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../assets/fonts/ubuntu/ubuntu-v20-latin-regular.woff') format('woff'),
        /* Modern Browsers */ url('../assets/fonts/ubuntu/ubuntu-v20-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../assets/fonts/ubuntu/ubuntu-v20-latin-regular.svg#Ubuntu') format('svg');
    /* Legacy iOS */
}

/* ubuntu-700 - latin */
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/ubuntu/ubuntu-v20-latin-700.eot');
    /* IE9 Compat Modes */
    src:
        local(''),
        url('../assets/fonts/ubuntu/ubuntu-v20-latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../assets/fonts/ubuntu/ubuntu-v20-latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../assets/fonts/ubuntu/ubuntu-v20-latin-700.woff') format('woff'),
        /* Modern Browsers */ url('../assets/fonts/ubuntu/ubuntu-v20-latin-700.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../assets/fonts/ubuntu/ubuntu-v20-latin-700.svg#Ubuntu') format('svg');
    /* Legacy iOS */
}

/* sanchez-regular - latin */
@font-face {
    font-family: 'Sanchez';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/sanchez/sanchez-v13-latin-regular.eot');
    /* IE9 Compat Modes */
    src:
        local(''),
        url('../assets/fonts/sanchez/sanchez-v13-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../assets/fonts/sanchez/sanchez-v13-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../assets/fonts/sanchez/sanchez-v13-latin-regular.woff') format('woff'),
        /* Modern Browsers */ url('../assets/fonts/sanchez/sanchez-v13-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../assets/fonts/sanchez/sanchez-v13-latin-regular.svg#Sanchez') format('svg');
    /* Legacy iOS */
}

/* Variable Font (Normal) */
@font-face {
    font-family: 'EB Garamond';
    src: url('../assets/fonts/EB_Garamond/EBGaramond-VariableFont_wght.ttf') format('truetype');
    font-weight: 100 800;
    font-style: normal;
}

/* Variable Font (Italic) */
@font-face {
    font-family: 'EB Garamond';
    src: url('../assets/fonts/EB_Garamond/EBGaramond-Italic-VariableFont_wght.ttf') format('truetype');
    font-weight: 100 800;
    font-style: italic;
}

/* Static Fallbacks */
@font-face {
    font-family: 'EB Garamond';
    src: url('../assets/fonts/EB_Garamond/static/EBGaramond-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'EB Garamond';
    src: url('../assets/fonts/EB_Garamond/static/EBGaramond-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'EB Garamond';
    src: url('../assets/fonts/EB_Garamond/static/EBGaramond-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'EB Garamond';
    src: url('../assets/fonts/EB_Garamond/static/EBGaramond-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'EB Garamond';
    src: url('../assets/fonts/EB_Garamond/static/EBGaramond-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'EB Garamond';
    src: url('../assets/fonts/EB_Garamond/static/EBGaramond-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'EB Garamond';
    src: url('../assets/fonts/EB_Garamond/static/EBGaramond-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'EB Garamond';
    src: url('../assets/fonts/EB_Garamond/static/EBGaramond-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'EB Garamond';
    src: url('../assets/fonts/EB_Garamond/static/EBGaramond-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'EB Garamond';
    src: url('../assets/fonts/EB_Garamond/static/EBGaramond-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}
