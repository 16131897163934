.status-link{
  cursor: pointer;
  .bullet{
    display: inline-block;
    background: $brand-background;
    border-radius: 50%;
    height: 0.75rem;
    width: 0.75rem;
    line-height: 0rem;
    margin-right: 0.25rem;

    &.bullet-md {
      height: 0.90rem;
      width: 0.90rem;
    }

    &.bullet-lg {
      height: 1.5rem;
      width: 1.5rem;
    }
    
    &.bullet-color- {
      background: $brand-background;
    }

    &.bullet-color-0 {
      background: $brand-warning;
    }

    &.bullet-color-1 {
      background: $brand-success;
    }

    &.bullet-color-x {
      background: $brand-secondary;
    }
    
  }
  &.draft{
    .bullet{
      &:nth-child(1){
        background: $brand-warning;
      }
    }
  }
  &.process{
    .bullet{
      background: $brand-warning;
    }
  }
  &.final{
    .bullet{
      background: $brand-warning;
      &:nth-child(1){
        background: $brand-success;
      }
    }
  }
  &.sent{
    .bullet{
      background: $brand-warning;
      &:nth-child(-n+2){
        background: $brand-success;
      }
    }
  }
  &.expired{
    .bullet{
      background: $brand-secondary;
      &:nth-child(-n+2){
        background: $brand-success;
      }
    }
  }
  &.accepted{
    .bullet{
      background: $brand-success;
    }
  }
  &.declined{
    .bullet{
      background: $brand-secondary;
    }
  }
  &.new{

  }
  &.contact-complete{
    .bullet{
      &:nth-child(1){
        background: $brand-success;
      }
    }
  }
  &.contact-sent{
    .bullet{
      &:nth-child(1){
        background: $brand-success;
      }
      &:nth-child(2){
        background: $brand-warning;
      }
    }
  }
  &.contact-extended-data{
    .bullet{
      &:nth-child(1){
        background: $brand-success;
      }
      &:nth-child(2){
        background: $brand-success;
      }
    }
  }
  &.contact-extended-data-complete{
    .bullet{
      background: $brand-success;
    }
  }
  &.contact-deleted{
    .bullet{
      &:nth-child(1){
        background: $brand-secondary;
      }
    }
  }
  &.event-created{
    .bullet{
      &:nth-child(1){
        background: $brand-success;
      }
    }
  }
  &.event-sendet{
    .bullet{
      &:nth-child(1){
        background: $brand-success;
      }
      &:nth-child(2){
        background: $brand-success;
      }
    }
  }
}
.status-tooltip {
  top: -1.55rem;
  left: -1.55rem;
  position: absolute;
  font-size: 14px;
}

.mat-radio-status{
  .mat-radio-button{
     display: block;
     margin-bottom: 10px;
    .mat-radio-label{
      display: block;
      .mat-radio-label-content{
        display: block;
        padding: 0;
      }
    }
  }
  .mat-radio-container{
    display: none;
  }


  .mat-radio-checked {
    .status-link {
      border: 1px solid $brand-primary;
    }
  }

  .mat-radio-disabled {
    .status-link {
      background: rgba(0, 0, 0, 0.05);
    }
    &.mat-radio-checked{
      .status-link {
        border: 1px solid rgba(0,0,0,.1);
      }
    }
  }

  .status-link{
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 50px;
    padding: 10px 15px;
    p{
      margin: 0;
      display: inline-block;
    }
  }
}
.bullets-container {
  display: flex;
  justify-content: space-around;
  gap: 10px;

  .status-col {
    flex: 1;
    border: 1px solid #ccc;
    text-align: center;

    .status-link {
      padding: 7px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .status-options {
      padding: 0px 15px 15px 15px;
      
      .status-link {
        padding: 7px 10px;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        border-radius: 5px;
        
        span {
          margin: 0;
          margin-right: 10px;
        }
      }
    }
  }



}