
@font-face {
    font-family: 'Carlito';
    src: url("../../assets/fonts/carlito/carlito-regular.woff") format('woff'),
         url("../../assets/fonts/carlito/carlito-regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
  }
@font-face {
    font-family: 'CarlitoBold';
    src: url("../../assets/fonts/carlito/carlito-bold.woff") format('woff'),
         url("../../assets/fonts/carlito/carlito-bold.ttf") format('truetype');
    font-weight: 700;
    font-style: normal;
  }

//     @font-face {
//         font-family: 'CarlitoItalic';
//         font-style: normal;
//         font-weight: normal;
//         src: url("../assets/fonts/carlito/carlito-italic.woff") format('woff');
//         src: url("../assets/fonts/carlito/carlito-italic.ttf") format('truetype');
//       }
//     @font-face {
//         font-family: 'CarlitoBoldItalic';
//         font-style: normal;
//         font-weight: normal;
//         src: url("../assets/fonts/carlito/carlito-bolditalic.woff") format('woff');
//         src: url("../assets/fonts/carlito/carlito-boldItalic.ttf") format('truetype');
//       }

body{
    margin: 0;
    background: $brand-background;
    color: $brand-text-dark;
    font-family: 'Carlito';
    overflow-x: hidden;
    position: relative;
    min-height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
}

main{
    padding: 0 15px 5px;
    max-width: 1352px;
    margin: auto;
}

app-background{
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: -1;
    .image, .over{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

ul,
ol,
li{
    list-style: none;
    padding-left: 0;
    margin:0;
}

img{
    max-width: 100%;
}
.right{
    float: right;
}
.left{
	float: left;
}

.relative{
    position: relative;
}
.img_right {
    float: right;
    width: 30%;
    margin-left: 15px;
}
.img_left {
	float: left;
    width: 30%;
    margin-right: 15px;
}
.img_50_right {
	float: right;
	width: 50%;
	margin-right: 15 px;
}
.img_50_left {
	float: left;
	width: 50%;
	margin-right: 15 px;
}

.btn-secondary{
    background: $brand-secondary;
    color: #fff!important;
}

.btn-search{
    background: rgba(#fff, 0.15);
    color: rgba(#fff, 0.5)!important;
    font-weight: 100;
    padding-right: 100px!important;
}

.btn-primary{
    background: $brand-primary;
    color: $brand-text-bright!important;
}

.btn-cancel{
     background: #e8e8e8;
    color: #000!important;
}

.btn-border{
     border: 1px solid rgba($brand-text-dark,0.5)!important;
}

.mat-card{
    color: $brand-text-dark;
    margin:5px;
}

.mat-toolbar{
    background: transparent;
    color: #fff;
    font-weight: 100;
}

.mat-toolbar-header{
    background: #fff;
    border-bottom: 1px solid rgba($brand-text-dark, 0.15);
    color: $brand-text-dark;
}
.mat-toolbar-layout{
    width: 100%;
    max-width: 1352px;
    margin: auto;
    position: relative;
}

.mat-tab-label-container {
    padding-left: 5px;
    .mat-tab-list {
        .mat-tab-labels {
            .mat-tab-label-active{
                background-color: #fff;
                border-radius: 5px;
                opacity: 1;
            }
            .mat-tab-label {
                color: #fff;
                font-weight: bold;
                opacity: 1;

                .mat-tab-label-content {
                    color: #fff;
                    font-weight: bold;
                }
            }
        }
    }
}

.contact-tab-black {
  .mat-tab-label-container {
      .mat-tab-list {
          .mat-tab-labels {
              .mat-tab-label {
                  color: #000;
                  font-weight: bold;
                  opacity: 1;

                  .mat-tab-label-content {
                      color: #000;
                      font-weight: bold;
                  }
              }
          }
      }
  }
}

.list-li{
    cursor: pointer;
    padding: 10px 5px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    a{
        color: $brand-text-dark;
    }
    &:hover{
        background: rgba(0,0,0,0.05);
    }
}


nav{
    &.sidenav{
        ul{
            li{
                padding: 10px 0;
                a{
                    color: $brand-text-dark;
                    text-decoration: none;
                    &:hover,
                    &:active,
                    &:focus{
                        transition: all 0.15s;
                        color: $brand-secondary;
                    }
                }
            }
        }
    }
}

.mat-drawer-container{
    background: none;
    position: absolute;
    width: 100vw;
    top:0;
    bottom:0;
    right:0;
    left:0;
}

.contactTable{

    .imgwrap{
        max-width: 50px;
        overflow: hidden;
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
        img{
            height:100%;
            max-height: 50px;
            max-width: 50px;
        }
    }

    .relative{
        p{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
        }
    }

    h3{
        margin: 0;
    }
    a{
        text-decoration: none;
    }
    li{
        &.head{
            border-bottom: 1px solid rgba(0,0,0,0.1);
            color: #969599;
            h5{
                font-weight: 100;
            }
        }
    }
}

.mat-dialog-container{
    position: relative;
}

.contactDetailDialogHead{
    width: 100%;
    position: absolute;
    margin: -24px -24px 0;

    .contactDetailDialogHead-wrap{
        background-color: $brand-background;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: table;
        padding: 24px;
        table-layout: fixed;
        min-width: 100%;
    }
    .contactDialogActions{
        li{
            display: inline-block;
            .contact--icon{
                &.mat-icon{
                    width: 14px;
                    height: 14px;
                    padding-left: 15px;
                    cursor: pointer;
                }
            }
        }
    }
    .cell1,
    .cell2,
    .cell3{
        display: table-cell;
        position: relative;
        vertical-align: middle;
    }
    .imgwrap{
        max-width: 100px;
        max-height: 100px;
        img{
            height:100%;
            width: 100%;
        }
    }
}

.contactDetailDialogFoot{
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0 -24px;

    .contactDetailDialogFoot-wrap{
        background-color: $brand-background;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: table;
        padding: 24px;
        table-layout: fixed;
        min-width: 100%;
    }
    .cell1,
    .cell2{
        display: table-cell;
        position: relative;
        vertical-align: middle;
    }
}

.contactDetailDialogCont{
    max-height: 60vh;
    overflow: scroll;
    margin-top: 60px;
    margin-bottom: 60px;
    padding: 0 30px 0 10px;

    .contactDetailList{
        .mat-icon{
            margin-top: 20px;
        }

        li{
            padding: 5px 0;

            .contact--txt{
                font-size: 14px;
                display: inline-block;
            }
            span{
                &.contact--info{
                    font-size: 80%;
                    color: rgba($brand-text-dark, 0.5);
                    margin-left: 5px;
                }
            }
            ul{
                &.contactBlock{
                    li{
                        padding: 0;
                    }
                }
                display: inline-grid;
            }
        }
    }
}

//.mat-drawer{
//    visibility: visible!important;
//    overflow: visible;
//    .offer--menu-open{
//        position: absolute;
//        top: 500px;
//        right: -60px;
//    }
//}

.pdf-link{
    position: absolute;
    right: 30px;
}

.mat-expansion-panel-body{
    padding: 0 24px 4px!important;
}

.text-color-red {
  color: #fd3e00;
}

.font-ubuntu{
  font-family: 'Ubuntu';
}

.font-sanchez{
  font-family:'Sanchez';
}

.font-carlito{
  font-family:'Carlito';
}

/******************/

.text-danger {
  color: red;
}

.mat-mdc-tooltip.tooltip {
  font-size: 14px;
}

.toast-info{
    background-color: #6B6B6C !important;
}

.mat-table .mat-row:hover,
.mat-table .mat-row.hovered{
    background-color: #def4ff;
}

.signature-image-container {
    height: 95px;
}
.signature-image-container img {
    height: 100%;
}

.spinner-div circle {
    stroke: #fd3e00 !important;
}
signature-pad {
    width: 100% !important;

    canvas {
        width: 100% !important;
    }
}
.mtx-calendar-header .mat-mdc-button:not(:disabled){
    color: #fff !important;
}
.mtx-calendar-header-hour-minute-separator {
    color: #000;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel {
    max-width: 350px !important;
}
@media screen and (max-width: 768px) {
    .app-menu-position-right,
    .app-menu-position-left {
        position: static !important;
    }
    .mat-toolbar.mat-toolbar-header.mat-toolbar-single-row {
        flex-direction: column !important;
        height: auto !important;
        padding: 20px 0 !important;
    }
    .logo {
        margin-bottom: 10px !important;
    }
    app-offer-attachment {
        padding-top: 150px !important;
    }
}