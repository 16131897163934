/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@at-root {
  @-ms-viewport { width: device-width; } // stylelint-disable-line at-rule-no-vendor-prefix
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@import "boostrap-grid/functions";
@import "boostrap-grid/variables";

@import "boostrap-grid/mixins/breakpoints";
@import "boostrap-grid/mixins/grid-framework";
@import "boostrap-grid/mixins/grid";

@import "boostrap-grid/grid";
@import "boostrap-grid/utilities/display";
@import "boostrap-grid/utilities/flex";

.row {
  > .col,
  > [class*="col-"] {

    ul {
      padding-left: 15px;

      li {
        list-style: square;
        margin-bottom: 6px;
      }
      &.circle {
        li {
          list-style: circle;
        }
      }
      &.disc {
        li {
          list-style: disc;
        }
      }
      &.checkbox {
        li {
          padding-left: 0.5em;
          list-style-type: '\25A2';
        }
      }
    }
    
    p {
        margin: 0 0 2mm;
        padding: 0;
    }

  }
}
