footer{
    position: absolute;
    //height: 64px;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 15px;
    max-width: 1352px;
    margin: auto;
    margin-bottom: -50px;

    .mat-toolbar-footer{
        padding: 0;
        background-color: transparent;
        .mat-toolbar-layout{
            border-top: 1px solid rgba(0,0,0,0.15);
            margin-top: 10px;
        }
    }
    a {
        font-size: 12px!important;
        color: #fff!important;
    }
    nav{
        right: 15px;
        position: absolute;
    }
    ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    li{
        a {
            font-weight: 300!important;
            color: #fff!important;
            text-transform: uppercase;
            text-decoration: none;
        }
    }
}