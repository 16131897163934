.cover_logo {
  /* position: absolute;
  left: 50%;              
  top: 50%;       
  transform: translate(-50%, -50%); */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ueberschrift-s {
  font-family:'CarlitoBold';
  font-size: 14pt;
  font-weight: 700;
  line-height: 1.08;
  color: #002c81;
  text-align: left;
}

.botschaft-s,
.botschaft-s-bold,
.botschaft-s-highlight {
  font-family:'Carlito';
  font-size: 12pt;
  font-weight: 300;
  line-height: 1.2;
  text-align: left;
  color: #333333;
}

.botschaft-s-bold {
  font-family: 'CarlitoBold';
}

.botschaft-s-highlight {
  font-family: 'CarlitoBold';
  color: #002c81;
}

.text-s,
.text-s-justify,
.text-s-bold,
.text-s-highlight,
.text-s-big,
.text-s-preis,
.text-s-tiny {
  font-family:'Carlito';
  font-size: 11pt;
  line-height: 1.2;
  text-align: left;
  color: #333333;
}

.text-s-justify {
  text-align: justify;
}

.text-s-bold {
  font-family: 'CarlitoBold';
}

.text-s-highlight {
  color: #002c81;
}

.text-s-big {
  font-size: 11pt;
}

.text-s-preis {
  text-align: right;
}

.text-s-tiny {
  font-size: 9pt;
  line-height: 1.2;
}

.caption-s,
.caption-s-highlight,
.caption-s-bold {
  font-family:'Carlito';
  font-size: 9pt;
  line-height: 1.2;
  text-align: center;
  color: #000000;}

.caption-s-highlight {
  color: #002c81;
}

.caption-s-bold {
  font-weight: 500;
}
