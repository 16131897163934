.dcs .ql-snow .ql-editor h1 {
  font-family:'Helvetica';
  font-size: 14pt;
  font-weight: 700;
  line-height: 1.08;
  color: #f39200;
  text-align: left;
}

.dcs .ql-snow .ql-editor h2 {
  font-family:'Helvetica';
  font-size: 11pt;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  color: #878787;
}

.dcs .ql-snow .ql-editor h3 {
  font-family: 'Helvetica';
  font-size: 16pt;
  font-weight: 700;
  line-height: 1.08;
  text-align: left;
  color: #f39200;
}

.dcs .ql-container {
  font-family:'Helvetica';
  font-size: 10pt;
  line-height: 1.2;
  text-align: left;
  color: #000000;
}

.dcs .ql-editor strong {
  font-family: 'Helvetica';
  font-weight: 600;
}

.dcs .ql-editor .ql-size-small {
  font-size: 8pt;
  line-height: 1.2;
}

.dcs .ql-editor .ql-size-large {
  font-size: 11pt;
}