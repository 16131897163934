.system--alert{
  width: 100%;
  background: $brand-primary-dark;
  color: #fff;
  padding: 15px 15px 0;
  h3, p{
    margin: 0;
    font-size: 0.875rem;
  }
  p{

  }

  .msg{
    padding-bottom: 15px;
  }

  .remove{
    background: none;
    color: #fff;
    border:none;
    outline:none;
    cursor: pointer;
    &:hover{
      color: rgba(255,255,255,0.5);
    }
  }

}