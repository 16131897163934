/* h1 */
.ueberschrift-form {
  font-family:'Roboto';
  font-size: 13pt;
  font-weight: 700;
  line-height: 1.08;
  /* color: #002c81; */
  text-align: left;
}

/* h2 */
.botschaft-form,
.botschaft-form-bold,
.botschaft-form-highlight {
  font-family:'Roboto';
  font-size: 11pt;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  color: #333333;
}

.botschaft-form-bold {
  font-weight: 700;
}

.botschaft-form-highlight {
  font-weight: 700;
  /* color: #002c81; */
}

.text-form,
.text-form-justify,
.text-form-bold,
.text-form-highlight,
.text-form-big,
.text-form-preis,
.text-form-tiny {
  font-family:'Roboto';
  font-size: 10pt;
  line-height: 1.2;
  text-align: left;
  /* color: #333333; */
  font-weight: 400;
}

.text-form-justify {
  text-align: justify;
}

.text-form-bold {
  font-weight: 700;
}

/* h3 */
.text-form-highlight {
  /* color: #002c81; */
}

.text-form-big {
  font-size: 11pt;
}

.text-form-preis {
  text-align: right;
}

.text-form-tiny {
  font-size: 8pt;
  line-height: 1.2;
}