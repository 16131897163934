.ueberschrift-dcs {
  font-family:'Helvetica';
  font-size: 14pt;
  font-weight: 700;
  line-height: 1.08;
  color: #f39200;
  text-align: left;
}

.botschaft-dcs,
.botschaft-dcs-bold,
.botschaft-dcs-highlight {
  font-family:'Helvetica';
  font-size: 11pt;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  color: #878787;
}

.botschaft-dcs-bold {
  font-family: 'Helvetica';
  font-weight: 600;
}

.botschaft-dcs-highlight {
  font-family: 'Helvetica';
  color: #f39200;
}

.text-dcs,
.text-dcs-justify,
.text-dcs-bold,
.text-dcs-highlight,
.text-dcs-big,
.text-dcs-preis,
.text-dcs-tiny {
  font-family:'Helvetica';
  font-size: 10pt;
  line-height: 1.2;
  text-align: left;
  color: #000000;
}

.text-dcs-justify {
  text-align: justify;
}

.text-dcs-bold {
  font-family: 'Helvetica';
  font-weight: 600;
}

.text-dcs-highlight {
  color: #f39200;
}

.text-dcs-big {
  font-size: 11pt;
}

.text-dcs-preis {
  text-align: right;
}

.text-dcs-tiny {
  font-size: 8pt;
  line-height: 1.2;
}

.caption-dcs,
.caption-dcs-highlight,
.caption-dcs-bold {
  font-family:'Helvetica';
  font-size: 9pt;
  line-height: 1.2;
  text-align: center;
  color: #000000;}

.caption-dcs-highlight {
  color: #f39200;
}

.caption-dcs-bold {
  font-weight: 500;
}